import { BaseModal } from './BaseModal'
import { hint } from '../../lib/words'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const HintModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Hint" isOpen={isOpen} handleClose={handleClose}>
      <div className="text-md text-gray-500 dark:text-gray-300">
        <div className="info-text">
          <b>{hint}</b>
          <br></br>
        </div>
      </div>
    </BaseModal>
  )
}
